import React, { useEffect, useRef, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import NavBar from '../components/NavBar';
import {
    fetchAndSetAnalyticsData,
    handleLibraryRequest,
} from '../utils/backendServices';
import UploadProgressBox from '../components/UploadProgressBox';
import { useLibraryContext } from '../contexts/LibraryContext';
import UserModal from '../components/UserModal';
import Spinner from '../components/Spinner';
import {
    UploadProgress,
    UploadProgressContext,
} from '../contexts/UploadProgressContext';
import Analytics from './Analytics';
import { useInterval } from '../hooks/useInterval';
import { useUploadProgress } from '../hooks/useUploadProgress';
import Upload from './Upload';
import Library from './Library';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import { useQuery } from '@tanstack/react-query';
import { isMobile } from 'react-device-detect';
import AssetDetails from '../components/AssetDetails';

const MainApp = () => {
    const [userModalVisible, setUserModalVisible] = useState<boolean>(false);
    const [firstRequestSuccessful, setFirstRequestSuccessful] =
        useState<boolean>(false);

    const handleUserModalClose = () => {
        setUserModalVisible(false);
    };
    const handleUserModalOpen = () => {
        setUserModalVisible(true);
    };

    const libraryContext = useLibraryContext();
    // todo: should be moved into Library Component
    useEffect(() => {
        handleLibraryRequest(libraryContext)
            .then(() => {
                setFirstRequestSuccessful(true);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [
        libraryContext.state.appliedFilters,
        libraryContext.state.searchString,
        libraryContext.state.appliedSortOption,
    ]);

    useInterval(() => {
        handleLibraryRequest(
            libraryContext,
            libraryContext.state.currentPage,
            false,
        ).catch((error) => {
            console.log(error);
        });
    }, 60000);

    const [uploadProgressArray, setUploadProgressArray] = useState<
        UploadProgress[]
    >([]);
    const uploadProgressArrayRef = useRef<UploadProgress[]>([]);
    uploadProgressArrayRef.current = uploadProgressArray;

    useUploadProgress(uploadProgressArrayRef, setUploadProgressArray);

    const analyticsContext = useAnalyticsContext();

    const { isLoading, refetch } = useQuery({
        queryFn: () => fetchAndSetAnalyticsData(analyticsContext),
        queryKey: ['analyticsData'],
        staleTime: Infinity,
        refetchInterval: 300000,
    });

    useEffect(() => {
        refetch();
    }, [analyticsContext.state.appliedFilters]);
    useEffect(() => {
        analyticsContext.setters.setIsLoading(isLoading);
    }, [isLoading]);

    const routes = [
        { path: '/upload', displayName: 'Upload', element: <Upload /> },
        { path: '/library', displayName: 'Library', element: <Library /> },
        {
            path: '/analytics',
            displayName: 'Analytics',
            element: <Analytics />,
        },
        {
            path: '/detail/:id',
            displayName: 'Detail',
            element: <AssetDetails />,
        },
    ];

    return (
        <>
            {!firstRequestSuccessful ? (
                <Spinner />
            ) : (
                <>
                    <NavBar
                        routes={routes}
                        handleShowUserModal={handleUserModalOpen}
                    />
                    <div className="App">
                        <UploadProgressContext.Provider
                            value={{
                                uploadProgressArray,
                                setUploadProgressArray,
                                uploadProgressArrayRef,
                            }}
                        >
                            <Routes>
                                <Route
                                    path="/"
                                    element={<Navigate to={'/library'} />}
                                />
                                {routes.map((route) => {
                                    return (
                                        <Route
                                            key={route.path}
                                            path={route.path}
                                            element={route.element}
                                        />
                                    );
                                })}
                            </Routes>
                            <UserModal
                                isActive={userModalVisible}
                                onClose={handleUserModalClose}
                            />
                            <UploadProgressBox />
                        </UploadProgressContext.Provider>
                    </div>
                </>
            )}
        </>
    );
};
export default MainApp;
