import React from 'react';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import { Data, Layout } from 'plotly.js';
import Chart from './Chart';

const OutcomeChart = () => {
    const outcomeAnalytics =
        useAnalyticsContext().state.analyticsData.outcomeAnalytics;

    type Order = 'Accept' | 'Warning' | 'Reject';
    const order: Order[] = ['Reject', 'Warning', 'Accept'];

    const outcomeData: Data[] = [
        {
            type: 'bar',
            x: order.map((key) => outcomeAnalytics[key] || 0),
            y: order,
            orientation: 'h',
            marker: {
                color: order.map((key) => {
                    if (key === 'Reject') return '#ff2400';
                    if (key === 'Warning') return '#eed202';
                    if (key === 'Accept') return '#5cb85c';
                }),
            },
        },
    ];

    const layout: Partial<Layout> = {
        margin: { pad: 10, b: 40, t: 60, r: 10, l: 60 },
        height: 350,
    };

    return <Chart data={outcomeData} layout={layout} title="Outcome" />;
};

export default OutcomeChart;
