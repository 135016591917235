import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from './Dropdown';
import ModalBase from './ModalBase';
import { RuleResultExtended } from '../types';

const StyledTextArea = styled.textarea`
    border-radius: 4px;
    border: 1px solid #bdbdbd;
    padding: 10px;
    width: 100%;
    height: calc(1.5em * 6);
    resize: none; /* Prevent resizing */
    box-sizing: border-box;
    overflow: auto;
`;

const RequiredIndicator = styled.span`
    padding-left: 4px;
    color: red;
`;

const ModalTitle = styled.h3`
    text-align: center;
    margin-top: 5px;
`;

const SubmitButton = styled.button`
    background-color: #4f4f4f;
    border-radius: 5px;
    border: none;
    color: white !important;
    padding: 10px 20px;
    width: 100%;
    cursor: pointer;
    font-family: 'Lato Bold', sans-serif;

    &:hover {
        background-color: #3e3e3e;
    }
`;

const DisabledSubmitButton = styled.button`
    // background-color: #d9d9d9;
    border-radius: 5px;
    border: none;
    color: #b9b9b9 !important;
    padding: 10px 20px;
    width: 100%;
    cursor: default;
    font-family: 'Lato Bold', sans-serif;
`;

const FeedbackModalWrapper = styled.div`
    padding: 20px;
    margin: 0;
    width: calc(100% - 40px);
    flex: 1;
`;

const FeedbackModalControlRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-equal;
    align-items: left;
    margin-bottom: 10px;
    gap: 20px;
`;

const FeedbackModalControlItem = styled.div`
    flex: 0.75;
`;

const FeedbackModalControlTitle = styled.div`
    flex: 0.25;
    font-family: 'Lato Bold', sans-serif;
`;

interface FeedbackModalProps {
    selectedRule: RuleResultExtended;
    onSubmit: (
        selectedRule: RuleResultExtended,
        newOuctome: string,
        comment: string,
    ) => void;
    onClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({
    selectedRule,
    onSubmit,
    onClose,
}) => {
    const [outcome, setOutcome] = useState<string>(selectedRule.outcome || '');
    const [comment, setComment] = useState('');

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'unset';
        };
    }, []);

    const items = [
        { value: 'Fail', label: 'Fail' },
        { value: 'Warning', label: 'Warning' },
        { value: 'Pass', label: 'Pass' },
    ];

    const handleSubmit = () => {
        onSubmit(selectedRule, outcome, comment);
        onClose();
    };

    return (
        <ModalBase onClose={onClose}>
            <FeedbackModalWrapper>
                <ModalTitle>User Feedback</ModalTitle>
                <FeedbackModalControlRow>
                    <FeedbackModalControlTitle>Rule</FeedbackModalControlTitle>
                    <FeedbackModalControlItem>
                        {selectedRule.rule_description}
                    </FeedbackModalControlItem>
                </FeedbackModalControlRow>
                <FeedbackModalControlRow>
                    <FeedbackModalControlTitle>
                        Reason
                    </FeedbackModalControlTitle>
                    <FeedbackModalControlItem>
                        {selectedRule.reason}
                    </FeedbackModalControlItem>
                </FeedbackModalControlRow>
                <FeedbackModalControlRow>
                    <FeedbackModalControlTitle>
                        Outcome
                    </FeedbackModalControlTitle>
                    <FeedbackModalControlItem>
                        <Dropdown
                            items={items}
                            selectedValue={outcome}
                            onSelect={setOutcome}
                            centered={false}
                        />
                    </FeedbackModalControlItem>
                </FeedbackModalControlRow>
                <FeedbackModalControlRow>
                    <FeedbackModalControlTitle>
                        Comment<RequiredIndicator>*</RequiredIndicator>
                    </FeedbackModalControlTitle>
                    <FeedbackModalControlItem>
                        <StyledTextArea
                            placeholder="Add comment..."
                            onChange={(e) => setComment(e.target.value)}
                            rows={4}
                        />
                    </FeedbackModalControlItem>
                </FeedbackModalControlRow>
                {comment !== '' ? (
                    <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
                ) : (
                    <DisabledSubmitButton>Submit</DisabledSubmitButton>
                )}
            </FeedbackModalWrapper>
        </ModalBase>
    );
};

export default FeedbackModal;
