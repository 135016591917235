import React from 'react';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import { Data, Layout } from 'plotly.js';
import Chart from './Chart';

const RuleFailureChart = () => {
    const ruleFailureAnalytics =
        useAnalyticsContext().state.analyticsData.ruleFailureAnalytics;

    const sortedRuleFailureData = Object.keys(ruleFailureAnalytics)
        .map((key) => ({
            count: ruleFailureAnalytics[key].count,
            rule_description: ruleFailureAnalytics[key].rule_description,
            short_rule_description:
                ruleFailureAnalytics[key].short_rule_description,
            rule_type: ruleFailureAnalytics[key].rule_type,
        }))
        .sort((a, b) => a.count - b.count)
        .slice(Object.keys(ruleFailureAnalytics).length - 50);

    const ruleFailureData: Data[] = [
        {
            type: 'bar',
            x: sortedRuleFailureData.map((data) =>
                data.rule_type == 'Fail' ? data.count : 0,
            ),
            y: sortedRuleFailureData.map((data) => data.short_rule_description),
            orientation: 'h',
            marker: {
                color: Array(sortedRuleFailureData.length).fill('#4f4f4f'),
            },
            name: 'Fail',
        },
        {
            type: 'bar',
            x: sortedRuleFailureData.map((data) =>
                data.rule_type == 'Warning' ? data.count : 0,
            ),
            y: sortedRuleFailureData.map((data) => data.short_rule_description),
            orientation: 'h',
            marker: {
                color: Array(sortedRuleFailureData.length).fill('#D3D3D3'),
            },
            name: 'Warning',
        },
    ];

    const layout: Partial<Layout> = {
        barmode: 'stack',
        margin: { pad: 10, b: 0, t: 100, r: 100, l: 470 },
        font: {
            family: 'Lato, sans-serif',
            size: 12,
            color: 'black',
        },
        height: 100 + sortedRuleFailureData.length * 20,
        showlegend: true,
        legend: {
            font: {
                family: 'Lato, sans-serif',
                size: 12,
                color: 'black',
            },
        },
        xaxis: {
            side: 'top',
        },
    };

    return <Chart data={ruleFailureData} layout={layout} title="Rules" />;
};

export default RuleFailureChart;
