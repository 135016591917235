import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';

type RotateImageProps = {
    imageUrl: string;
    width: number;
    height: number;
};

const RotateImage: React.FC<RotateImageProps> = ({
    imageUrl,
    width,
    height,
}) => {
    const [angle, setAngle] = useState(0);

    const handleIconClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setAngle((prevAngle) => (prevAngle + 90) % 360);
    };

    const containerStyle = {
        width: isMobile ? '100%' : `${width}px`,
        height: isMobile ? '100%' : `${height}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        cursor: 'zoom-in',
        // eslint-disable-next-line @typescript-eslint/prefer-as-const
        position: 'relative' as 'relative',
    };

    const iconStyle = {
        // eslint-disable-next-line @typescript-eslint/prefer-as-const
        position: 'absolute' as 'absolute',
        top: '5px',
        right: '5px',
        cursor: 'pointer',
        zIndex: 200,
        color: '#fff',
        backgroundColor: '#4F4F4F',
        borderRadius: '50%',
        width: '18px',
        height: '18px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '6px',
    };

    const imageStyle = {
        maxWidth: '100%',
        maxHeight: '100%',
        transform: `rotate(${angle}deg)`,
        transition: 'transform 0.5s ease',
    };

    return (
        <div style={containerStyle}>
            <div style={iconStyle} onClick={handleIconClick}>
                <FontAwesomeIcon color="white" fontSize={12} icon={faRedo} />
            </div>
            <img src={imageUrl} alt="Rotating" style={imageStyle} />
        </div>
    );
};

export default RotateImage;
