import React, { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ComplianceItem, RuleResultExtended } from '../types';
import {
    deleteAsset,
    fetchAndSetAnalyticsData,
    fetchItemDetail,
    handleLibraryRequest,
    submitFeedbackForItem,
} from '../utils/backendServices';
import Spinner from './Spinner';
import FeedbackModal from './FeedbackModal';
import DeleteModal from './DeleteModal';
import { useLibraryContext } from '../contexts/LibraryContext';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import AssetDetailsBrowser from './AssetDetailsBrowser';
import { isMobile } from 'react-device-detect';
import AssetDetailsMobile from './AssetDetailsMobile';

const AssetDetails: FunctionComponent = () => {
    const libraryContext = useLibraryContext();
    const analyticsContext = useAnalyticsContext();

    const { id } = useParams<{ id: string }>();
    const [item, setItem] = useState<ComplianceItem | null>(null);
    const [error, setError] = useState<string | null>(null);

    const [isSubmittingFeedback, setIsSubmittingFeedback] = useState(false);

    const [imageUrls, setImageUrls] = useState<{ [id: string]: string }>({});

    const [isModalVisible, setIsModalVisible] = useState<boolean>(true);
    const [isDeleteModalVisible, setIsDeleteModalVisible] =
        useState<boolean>(false);

    const [selectedRule, setSelectedRule] = useState<RuleResultExtended | null>(
        null,
    );

    const handleRuleSelected = (rule: RuleResultExtended) => {
        setSelectedRule(rule);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setSelectedRule(null);
    };

    const handleSubmitFeedback = async (
        selectedRule: RuleResultExtended,
        newOuctome: string,
        comment: string,
    ) => {
        if (id) {
            setIsModalVisible(false);
            setSelectedRule(null);
            setIsSubmittingFeedback(true);
            const response = await submitFeedbackForItem(
                id,
                selectedRule.rule_id,
                {
                    comment: comment,
                    new_outcome: newOuctome,
                },
            );
            setItem(response.data);
            setIsSubmittingFeedback(false);
            handleLibraryRequest(libraryContext, 1, false).catch((error) => {
                console.log(error);
            });
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        // This hook is for fetching data
        if (!id) {
            setError('Item not found.');
            return;
        }
        const fetchData = async () => {
            try {
                const response = await fetchItemDetail(id);
                const itemData = response.data;
                setItem(itemData as unknown as ComplianceItem);

                setImageUrls(response.image_url);
            } catch (error) {
                console.error(error);
                setError('Error fetching item details.');
            }
        };

        fetchData();
    }, [id]);

    const handleDeleteButton = () => {
        setIsDeleteModalVisible(true);
    };
    const handleDeleteConfirmation = () => {
        if (item) {
            setIsDeleteModalVisible(false);
            libraryContext.setters.setLibraryIsLoading(true);
            navigate('/library');
            deleteAsset(item.id)
                .then(() => {
                    handleLibraryRequest(libraryContext).catch((error) => {
                        console.log(error);
                    });

                    fetchAndSetAnalyticsData(analyticsContext);
                })
                .catch((error) => {
                    console.error('Error deleting asset:', error);
                });
        }
    };

    const closeDeleteModal = () => {
        setIsDeleteModalVisible(false);
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!item || isSubmittingFeedback) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Spinner title="Loading..." />
            </div>
        );
    }

    return (
        <div className="detail-container">
            {isModalVisible && selectedRule && (
                <FeedbackModal
                    selectedRule={selectedRule}
                    onSubmit={handleSubmitFeedback}
                    onClose={handleCloseModal}
                />
            )}
            <DeleteModal
                isActive={isDeleteModalVisible}
                onClose={closeDeleteModal}
                onDelete={handleDeleteConfirmation}
            />
            {!isMobile ? (
                <AssetDetailsBrowser
                    item={item}
                    imageUrls={imageUrls}
                    handleDeleteButton={handleDeleteButton}
                    handleRuleSelected={handleRuleSelected}
                />
            ) : (
                <AssetDetailsMobile
                    item={item}
                    imageUrls={imageUrls}
                    handleDeleteButton={handleDeleteButton}
                    handleRuleSelected={handleRuleSelected}
                />
            )}
        </div>
    );
};

export default AssetDetails;
