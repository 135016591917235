import React, { FunctionComponent } from 'react';
import AnalyticsControlContainer from '../components/AnalyticsControlContainer';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import Spinner from '../components/Spinner';
import Charts from '../components/Charts';

const Analytics: FunctionComponent = () => {
    const analyticsContext = useAnalyticsContext();

    return (
        <div className="wrapper-container">
            <AnalyticsControlContainer />
            {analyticsContext.state.isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Spinner title="Loading..." />
                </div>
            ) : (
                <Charts />
            )}
        </div>
    );
};

export default Analytics;
