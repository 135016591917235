export async function rotateImage(imageFile: File): Promise<Blob | null> {
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);

    return new Promise<Blob | null>((resolve) => {
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d')!;

            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            canvas.toBlob(resolve);
        };
    });
}
