import React, { FunctionComponent, useState } from 'react';
import { ComplianceItem } from '../types';
import RotateImage from '../RotateImage';
import PannableImage from '../PannableImage';
import { isMobile } from 'react-device-detect';

interface AssetDetailImageProps {
    item: ComplianceItem;
    imageUrls: {
        [id: string]: string;
    };
}

const AssetDetailsImage: FunctionComponent<AssetDetailImageProps> = ({
    item,
    imageUrls,
}) => {
    const [showRotateImage, setShowRotateImage] = useState(true);

    const toggleVisibility = () => {
        setShowRotateImage(!showRotateImage);
    };

    return (
        <div className={`pan-rotate-container ${isMobile ? 'mobile' : ''}`}>
            {showRotateImage ? (
                <div onClick={toggleVisibility}>
                    <RotateImage
                        imageUrl={imageUrls[item.image_id]}
                        width={400}
                        height={400}
                    />
                </div>
            ) : (
                <div onClick={toggleVisibility}>
                    <PannableImage
                        imageUrl={imageUrls[item.image_id]}
                        width={400}
                        height={400}
                    />
                </div>
            )}
        </div>
    );
};

export default AssetDetailsImage;
