import React, { useRef } from 'react';

interface PannableImageProps {
  imageUrl: string;
  width: number; 
  height: number; 
}

const PannableImage: React.FC<PannableImageProps> = ({ imageUrl, width, height }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (containerRef.current) {
      const { left, top } = containerRef.current.getBoundingClientRect();

      const x = (e.clientX - left) / width;
      const y = (e.clientY - top) / height;

      const image = containerRef.current.children[0] as HTMLImageElement;
      const imageWidth = image.naturalWidth;
      const imageHeight = image.naturalHeight;

      const positionX = (width - imageWidth) * x;
      const positionY = (height - imageHeight) * y;

      image.style.transform = `translate(${positionX}px, ${positionY}px)`;
    }
  };

  return (
    <div
      ref={containerRef}
      onMouseMove={onMouseMove}
      style={{
        width: `${width}px`,  
        height: `${height}px`,
        overflow: 'hidden',
        position: 'relative',
        cursor: 'zoom-out', 
      }}
    >
      <img
        src={imageUrl}
        alt="Pannable content"
        style={{
          position: 'absolute',
          maxWidth: 'none',  
          maxHeight: 'none',
          width: 'auto',
          height: 'auto',
          top: '0',
          left: '0',
          transition: 'transform 0.02s ease-out', 
        }}
      />
    </div>
  );
};

export default PannableImage;
