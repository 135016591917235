import React, {
    useRef,
    useState,
    ChangeEvent,
    useImperativeHandle,
    forwardRef,
    DragEvent,
} from 'react';
// import { uploadAsset } from '../backendServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faImages, faTrash } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';

interface UploadBoxProps {
    setSelectedFile: (file: File | null) => void;
    selectedFile: File | null;
    handleUploadClick: () => Promise<void>;
}

export interface UploadBoxHandles {
    handleUploadClick: () => Promise<void>;
}

// eslint-disable-next-line react/display-name
const UploadBox = forwardRef<UploadBoxHandles, UploadBoxProps>((props, ref) => {
    const { selectedFile, setSelectedFile, handleUploadClick } = props;
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [isDragging, setIsDragging] = useState(false);

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            validateFile(file);
        }
    };

    const validateFile = (file: File) => {
        const fileType = file.name.split('.').pop()?.toLowerCase();
        if (!['jpg', 'jpeg', 'png'].includes(fileType || '')) {
            alert('Invalid file format. Please upload a JPG or PNG file.');
        } else if (file.size > 10 * 1024 * 1024) {
            // 10 mb
            alert('File size should not exceed 10MB');
            return;
        } else {
            setSelectedFile(file);
        }
    };

    const handleDrag = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDragIn = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(true);
    };

    const handleDragOut = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(false);
    };

    const handleDrop = (event: DragEvent<HTMLDivElement>) => {
        handleDrag(event);
        setIsDragging(false);
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {
            const file = files[0];
            validateFile(file);
        }
    };

    const triggerFileUpload = () => {
        fileInputRef.current?.click();
    };

    const handleDelete = () => {
        setSelectedFile(null);
    };

    useImperativeHandle(ref, () => ({
        handleUploadClick,
    }));

    return (
        <>
            {selectedFile === null ? (
                <>
                    <input
                        type="file"
                        ref={fileInputRef}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        accept=".jpg, .png, .jpeg"
                    />
                    <div
                        className={`upload-box ${isDragging ? 'dragging' : ''}`}
                        onClick={triggerFileUpload}
                        onDragOver={handleDrag}
                        onDragEnter={handleDragIn}
                        onDragLeave={handleDragOut}
                        onDrop={handleDrop}
                    >
                        <FontAwesomeIcon
                            icon={faImages}
                            className="image-icon"
                        />
                        <div className="upload-message">
                            {'Drag your image here or '}
                            <span className="browse-text">browse files</span>
                        </div>
                        <div className="upload-filetype-message">
                            Supports: JPG, JPEG, PNG
                        </div>
                    </div>
                </>
            ) : (
                <div className="preview-box">
                    <img
                        className={isMobile ? 'mobile' : ''}
                        src={URL.createObjectURL(selectedFile)}
                        alt={selectedFile.name}
                    />
                    <div className="info-container">
                        <div className="file-name">{selectedFile.name}</div>
                        <div className="file-size">
                            {`${
                                Math.round(
                                    (selectedFile.size / (1024 * 1024) +
                                        Number.EPSILON) *
                                        100,
                                ) / 100
                            } MB`}
                        </div>
                        <div className="approved-container">
                            <div className="approved-box">
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    className="approved-icon"
                                ></FontAwesomeIcon>
                            </div>
                            <div className="approved-text">{`Valid${
                                !isMobile ? ' image' : ''
                            }`}</div>
                        </div>
                    </div>
                    <div className="delete-box" onClick={handleDelete}>
                        <FontAwesomeIcon
                            icon={faTrash}
                            className="delete-icon"
                        ></FontAwesomeIcon>
                    </div>
                </div>
            )}
        </>
    );
});

export default UploadBox;
