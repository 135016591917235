import React, { FunctionComponent } from 'react';
import { ComplianceItem, RuleResultExtended } from '../types';
import AssetDetailsImage from './AssetDetailsImage';
import AssetDetailsInformation from './AssetDetailsInformation';
import AssetDetailsTitleBar from './AssetDetailsTitleBar';
import AssetDetailsBackButton from './AssetDetailsBackButton';

interface AssetDetailsBrowserProps {
    item: ComplianceItem;
    imageUrls: {
        [id: string]: string;
    };
    handleDeleteButton: () => void;
    handleRuleSelected: (rule: RuleResultExtended) => void;
}

const AssetDetailsBrowser: FunctionComponent<AssetDetailsBrowserProps> = ({
    item,
    imageUrls,
    handleDeleteButton,
    handleRuleSelected,
}) => {
    return (
        <>
            <div className="fixed-container">
                <AssetDetailsBackButton />
                <AssetDetailsImage item={item} imageUrls={imageUrls} />
            </div>
            <div className="rule-results-detail-container">
                <AssetDetailsTitleBar
                    itemName={item.user_input.asset_name}
                    onDelete={handleDeleteButton}
                    decision={item.outcome.decision}
                />
                <AssetDetailsInformation
                    item={item}
                    handleRuleSelected={handleRuleSelected}
                />
            </div>
        </>
    );
};

export default AssetDetailsBrowser;
