import React, { useEffect, useState } from 'react';
import { fetchAccessToken } from '../utils/backendServices';
import customer_logo from './../algospark-logo-black-full.svg';
import { useConfigContext } from '../contexts/ConfigContext';
import Spinner from '../components/Spinner';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const BASE_URL = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';

const Login = () => {
    const configData = useConfigContext();
    if (configData) {
        const navigate = useNavigate();
        const [code, setCode] = useState(false);
        const [isLoading, setIsLoading] = useState(true);

        useEffect(() => {
            const urlParams = new URLSearchParams(window.location.search);
            const codeParam = urlParams.get('code');

            if (codeParam) {
                setCode(true);
                fetchAccessToken(codeParam)
                    .then((response) => {
                        console.log(response);
                        navigate('/');
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            setIsLoading(false);
        }, []);

        const navigateToLogin = () => {
            if (BASE_URL === 'http://localhost:8000') {
                window.location.href = BASE_URL + `/login`;
            } else {
                window.location.href = BASE_URL + `/api/login`;
            }
        };

        if (isLoading) {
            return null;
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                {!code && (
                    <div
                        className={`login-title-container ${
                            isMobile && 'mobile'
                        }`}
                    >
                        <img
                            src={customer_logo}
                            alt="Brand logo"
                            className="brand-logo-login"
                        />
                        <div className="login-title">
                            {configData.general.title}
                        </div>
                        <div className="login-button" onClick={navigateToLogin}>
                            Log in
                        </div>
                    </div>
                )}
            </div>
        );
    } else {
        return <Spinner />;
    }
};

export default Login;
