import React, { FunctionComponent } from 'react';
import Pagination from '../components/Pagination';
import Spinner from '../components/Spinner';
import { useLibraryContext } from '../contexts/LibraryContext';
import LibraryControlContainer from '../components/LibraryControlContainer';
import AssetGallery from '../components/AssetGallery';

const Library: FunctionComponent = () => {
    const libraryContext = useLibraryContext();
    const isLoading = libraryContext.state.libraryIsLoading;

    const displayedItems = libraryContext.state.complianceTableState;

    const isPrevPageAvailable = libraryContext.state.currentPage > 1;
    const isNextPageAvailable =
        libraryContext.state.currentPage < libraryContext.state.totalPages;

    return (
        <div className="wrapper-container">
            <LibraryControlContainer />
            {isLoading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <Spinner title="Loading..." />
                </div>
            )}
            {!isLoading && (
                <AssetGallery
                    imageUrls={libraryContext.state.imageUrls}
                    complianceTableState={displayedItems}
                />
            )}
            {displayedItems.length > 0 && (
                <Pagination
                    totalPages={libraryContext.state.totalPages}
                    isPrevPageAvailable={isPrevPageAvailable}
                    isNextPageAvailable={isNextPageAvailable}
                    currentPage={libraryContext.state.currentPage}
                />
            )}
        </div>
    );
};

export default Library;
