import React, { FunctionComponent } from 'react';
import { ComplianceItem, RuleResultExtended } from '../types';
import AssetDetailsImage from './AssetDetailsImage';
import AssetDetailsInformation from './AssetDetailsInformation';
import AssetDetailsTitleBar from './AssetDetailsTitleBar';
import AssetDetailsBackButton from './AssetDetailsBackButton';

interface AssetDetailsMobileProps {
    item: ComplianceItem;
    imageUrls: {
        [id: string]: string;
    };
    handleDeleteButton: () => void;
    handleRuleSelected: (rule: RuleResultExtended) => void;
}

const AssetDetailsMobile: FunctionComponent<AssetDetailsMobileProps> = ({
    item,
    imageUrls,
    handleDeleteButton,
    handleRuleSelected,
}) => {
    return (
        <div className="rule-results-detail-container mobile">
            <AssetDetailsBackButton />
            <AssetDetailsTitleBar
                itemName={item.user_input.asset_name}
                onDelete={handleDeleteButton}
                decision={item.outcome.decision}
            />
            <AssetDetailsImage item={item} imageUrls={imageUrls} />
            <AssetDetailsInformation
                item={item}
                handleRuleSelected={handleRuleSelected}
            />
        </div>
    );
};

export default AssetDetailsMobile;
