import React, { FunctionComponent } from 'react';
import OutcomeChart from './OutcomeChart';
import UserChart from './UserChart';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import RuleFailureChart from './RuleFailureChart';
import { isMobile } from 'react-device-detect';

const Charts: FunctionComponent = () => {
    const analyticsContext = useAnalyticsContext();

    const showOutcomeandUserCharts =
        Object.keys(analyticsContext.state.analyticsData.outcomeAnalytics)
            .length > 0 &&
        Object.keys(analyticsContext.state.analyticsData.userAnalytics).length >
            0;
    const showRuleFailureChart =
        Object.keys(analyticsContext.state.analyticsData.ruleFailureAnalytics)
            .length > 0;

    return (
        <>
            {!isMobile ? (
                <>
                    <div className="chart-row">
                        {showOutcomeandUserCharts && (
                            <>
                                <OutcomeChart />
                                <UserChart />
                            </>
                        )}
                    </div>
                    {showRuleFailureChart && (
                        <div className="chart-row">
                            <RuleFailureChart />
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className="chart-row">
                        {showOutcomeandUserCharts && <OutcomeChart />}
                    </div>
                    {showRuleFailureChart && (
                        <div className="chart-row">
                            <UserChart />
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default Charts;
