import React from 'react';

type RuleOutcomeIndicatorProps = {
    decision: 'Fail' | 'Pass' | 'Warning' | string;
};

const RuleOutcomeIndicator: React.FC<RuleOutcomeIndicatorProps> = ({
    decision,
}) => {
    let decisionClass = 'status-indicator ';
    let decisionText = '';

    if (decision === 'Warning') {
        decisionClass += 'warning';
        decisionText = 'Warning';
    } else if (decision === 'Pass') {
        decisionClass += 'pass';
        decisionText = 'Pass';
    } else if (decision === 'Fail') {
        decisionClass += 'fail';
        decisionText = 'Fail';
    }

    if (!decisionText) return null;

    return <div className={decisionClass}>{decisionText}</div>;
};

export default RuleOutcomeIndicator;
