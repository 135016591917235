import React from 'react';
import { ExtractedText } from '../types';

interface SummaryTableProps {
    extractedText: ExtractedText | null;
}

const TextTable: React.FC<SummaryTableProps> = ({ extractedText }) => {
    return (
        <div className="summary-container">
            <h3>Text</h3>
            {extractedText && extractedText.native_text !== '' ? (
                <>
                    {extractedText !== null &&
                        extractedText.language_code !== 'en' && (
                            <div className="summary-row">
                                <div className="summary-title">Native Text</div>
                                <div className="summary-content">
                                    {extractedText.native_text}
                                </div>
                            </div>
                        )}
                    {extractedText !== null &&
                        extractedText.language_code !== 'en' && (
                            <div className="summary-row">
                                <div className="summary-title">
                                    English Text
                                </div>
                                <div className="summary-content">
                                    {extractedText.en_text}
                                </div>
                            </div>
                        )}
                    {extractedText !== null &&
                        extractedText.language_code == 'en' && (
                            <div className="summary-row">
                                <div className="summary-title">
                                    English Text
                                </div>
                                <div className="summary-content">
                                    {extractedText.native_text}
                                </div>
                            </div>
                        )}
                </>
            ) : (
                <>No text detected.</>
            )}
        </div>
    );
};

export default TextTable;
