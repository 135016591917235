import React from 'react';

interface SummaryTableProps {
    rulesFailed: string[];
    warnings: string[];
}

const SummaryTable: React.FC<SummaryTableProps> = ({
    rulesFailed,
    warnings,
}) => {
    return (
        <div className="summary-container">
            <h3>Summary</h3>

            {rulesFailed.length > 0 && (
                <div className="summary-row">
                    <div className="summary-title">Rules Failed</div>
                    <ol className="summary-content" type="1">
                        {rulesFailed.map((rule, index) => (
                            <li key={index} className="summary-item">
                                {rule}
                            </li>
                        ))}
                    </ol>
                </div>
            )}

            {warnings.length > 0 && (
                <div className="summary-row">
                    <div className="summary-title">Warnings</div>
                    <ol className="summary-content" type="1">
                        {warnings.map((warning, index) => (
                            <li key={index} className="summary-item">
                                {warning}
                            </li>
                        ))}
                    </ol>
                </div>
            )}
            {warnings.length == 0 && rulesFailed.length == 0 && (
                <div className="summary-row">
                    The advertisement complies with all rules.
                </div>
            )}
        </div>
    );
};

export default SummaryTable;
