import React from 'react';
import { isMobile } from 'react-device-detect';

interface UserInputTableProps {
    assetName: string;
    industry: string;
    country: string;
    user: string;
    uploadTime: string;
    id: string;
    rulesFlagged: string;
}

const UserInputTable: React.FC<UserInputTableProps> = ({
    assetName,
    industry,
    country,
    user,
    uploadTime,
    id,
    rulesFlagged,
}) => {
    const runInfo = (
        <>
            <div className="user-input-row">
                <div className="user-input-label">User</div>
                <div className="user-input-value">{user}</div>
            </div>
            <div className="user-input-row">
                <div className="user-input-label">Upload time</div>
                <div className="user-input-value">{uploadTime}</div>
            </div>
            <div className="user-input-row">
                <div className="user-input-label">ID</div>
                <div className="user-input-value">{id}</div>
            </div>
            <div className="user-input-row">
                <div className="user-input-label">Rules flagged</div>
                <div className="user-input-value">{rulesFlagged}</div>
            </div>
        </>
    );
    const userInput = (
        <>
            {' '}
            <div className="user-input-row">
                <div className="user-input-label">Asset name</div>
                <div className="user-input-value">{assetName}</div>
            </div>
            <div className="user-input-row">
                <div className="user-input-label">Industry</div>
                <div className="user-input-value">{industry}</div>
            </div>
            <div className="user-input-row">
                <div className="user-input-label">Country</div>
                <div className="user-input-value">{country}</div>
            </div>
        </>
    );
    return (
        <>
            <h3>User Input</h3>
            {!isMobile ? (
                <div className="user-input-table">
                    <div className="user-input-column">{runInfo}</div>
                    <div className="user-input-column">{userInput}</div>
                </div>
            ) : (
                <div className="user-input-table">
                    <div className="user-input-column">
                        {runInfo}
                        {userInput}
                    </div>
                </div>
            )}
        </>
    );
};

export default UserInputTable;
