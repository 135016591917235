import React from 'react';

type StatusIndicatorProps = {
    decision: 'Fail' | 'Pass' | 'Warning' | string;
    isBig: boolean;
};

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ decision, isBig }) => {
    let decisionClass = isBig ? 'status-indicator-big ' : 'status-indicator ';
    let decisionText = '';

    if (decision === 'Reject') {
        decisionClass += 'fail';
        decisionText = 'Reject';
    } else if (decision === 'Accept') {
        decisionClass += 'pass';
        decisionText = 'Accept';
    } else if (decision === 'Warning') {
        decisionClass += 'warning';
        decisionText = 'Warning';
    }

    if (!decisionText) return null;

    return <div className='status-indicator-container'><div className={decisionClass}>{decisionText}</div></div>;
};

export default StatusIndicator;
