import React from 'react';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import { Data, Layout } from 'plotly.js';
import Chart from './Chart';

const UserChart = () => {
    const userAnalytics =
        useAnalyticsContext().state.analyticsData.userAnalytics;

    // Prepare the data for the user bar chart
    const userData: Data[] = [
        {
            type: 'bar',
            x: Object.values(userAnalytics),
            y: Object.keys(userAnalytics),
            orientation: 'h',
            marker: {
                color: Array(Object.keys(userAnalytics).length).fill('#4f4f4f'),
            },
            transforms: [
                {
                    type: 'sort',
                    target: 'x',
                    order: 'ascending',
                },
            ],
        },
    ];

    const layout: Partial<Layout> = { height: 350 };

    return <Chart data={userData} layout={layout} title="User" />;
};

export default UserChart;
