import React from 'react';
import './Switch.css';

interface SwitchProps {
  isChecked: boolean;
  onChange: () => void;
}

const Switch: React.FC<SwitchProps> = ({ isChecked, onChange }) => {
  return (
    <>
      <input 
        type="checkbox" 
        id="switch" 
        checked={isChecked} 
        onChange={onChange} 
        className="switch-checkbox"
      />
      <label htmlFor="switch" className="switch-label">Toggle</label>
    </>
  );
};

export default Switch;
