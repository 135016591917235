import React, { FunctionComponent } from 'react';
import Plot from 'react-plotly.js';
import { Data, Layout } from 'plotly.js';

interface ChartProps {
    title: string;
    data: Data[];
    layout: Partial<Layout>;
}

const Chart: FunctionComponent<ChartProps> = ({ title, data, layout }) => {
    const defaultLayout: Partial<Layout> = {
        font: {
            family: 'Lato, sans-serif',
            size: 12,
            color: 'black',
        },
        margin: { pad: 10, b: 40, t: 60, r: 10, l: 150 },
        title: {
            font: {
                family: 'Lato Bold, sans-serif',
                color: 'black',
                size: 16,
            },
            text: title,
        },
    };

    return (
        <div className="chart-container">
            <Plot
                data={data}
                layout={{ ...defaultLayout, ...layout }}
                style={{ width: '100%', height: '100%' }}
                config={{ displayModeBar: false }}
            />
        </div>
    );
};

export default Chart;
