import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface CircularIconProps {
    icon: IconDefinition;
    size?: number;
    borderColor?: string;
    onClick?: () => void;
}

const CircularIcon: React.FC<CircularIconProps> = ({
    icon,
    size = 12,
    borderColor = '#000',
    onClick = () => alert('Icon clicked!'),
}) => {
    const style = {
        border: `2px solid ${borderColor}`,
        borderRadius: '50%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px',
        width: `${size}px`,
        height: `${size}px`,
        cursor: 'pointer',
    };

    return (
        <div className="circular-icon">
            <FontAwesomeIcon icon={icon} style={style} onClick={onClick} />
        </div>
    );
};

export default CircularIcon;
