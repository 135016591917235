import React, { useEffect, useState } from 'react';
import ModalBase from './ModalBase';
import { UserInfo } from '../types';
import { fetchUserInfo } from '../utils/backendServices';
import axios from 'axios';

interface UserModalProps {
    isActive: boolean;
    onClose: () => void;
}
let baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
if (baseURL !== 'http://localhost:8000') {
    baseURL += '/api';
}

const UserModal: React.FC<UserModalProps> = ({ isActive, onClose }) => {
    const [userInfo, setUserInfo] = useState<UserInfo | null>(null);

    useEffect(() => {
        fetchUserInfo()
            .then((userInfo) => {
                setUserInfo(userInfo);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const handleLogout = async () => {
        sessionStorage.clear();

        try {
            const logoutUrl = `${baseURL}/logout`;

            const response = await axios.get(logoutUrl, {
                withCredentials: true,
            });

            if (response.status === 200) {
                window.location.href =
                    'https://login.microsoftonline.com/435df8f0-bc0a-4c76-9078-b769d724e658/oauth2/v2.0/logout';
            } else {
                console.error(
                    `Logout request failed with status: ${response.status}`,
                );
            }
        } catch (error) {
            console.error(error);
        }
    };

    return isActive && userInfo ? (
        <ModalBase onClose={onClose}>
            <div className="user-modal-content">
                <h2>User</h2>
                <div className="user-modal-table">
                    <div className="user-modal-row">
                        <div className="label">Name</div>
                        <div className="value">{userInfo.username}</div>
                    </div>
                    <div className="user-modal-row">
                        <div className="label">Email</div>
                        <div className="value">{userInfo.email}</div>
                    </div>
                </div>
                <div className="logout-button" onClick={handleLogout}>
                    Log out
                </div>
            </div>
        </ModalBase>
    ) : null;
};

export default UserModal;
