import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ComplianceItem } from '../types';
import AssetCard from './AssetCard';

interface ResultsTableProps {
    complianceTableState: ComplianceItem[];
    imageUrls: { [id: string]: string };
}

const AssetGallery: React.FC<ResultsTableProps> = ({
    complianceTableState,
    imageUrls,
}) => {
    const navigate = useNavigate();
    // const [imageUrls, setImageUrls] = useState<{ [id: string]: string }>({});
    const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

    const handleSelect = (item: ComplianceItem) => {
        setSelectedItemId(item.id);
        navigate(`/detail/${item.id}`);
    };

    return (
        <div className="results-table-grid">
            {complianceTableState.length > 0 ? (
                complianceTableState.map((item) => (
                    <AssetCard
                        key={item.id}
                        item={item}
                        imageUrl={imageUrls[item.image_id]}
                        onSelect={handleSelect}
                        isSelected={selectedItemId === item.id}
                    />
                ))
            ) : (
                <div className="no-results-message">
                    No results for that filter
                </div>
            )}
        </div>
    );
};

export default AssetGallery;
