import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import StatusIndicator from './StatusIndicator';

interface AssetDetailsTitleBarProps {
    itemName: string;
    onDelete: () => void;
    decision: string;
}

const AssetDetailsTitleBar: React.FC<AssetDetailsTitleBarProps> = ({
    itemName,
    onDelete,
    decision,
}) => {
    return (
        <div className="approval-container">
            <h2>{itemName}</h2>
            <div className="approval-actions">
                <div className="approval-delete" onClick={onDelete}>
                    <FontAwesomeIcon icon={faTrash} />
                </div>
                <StatusIndicator decision={decision} isBig={true} />
            </div>
        </div>
    );
};

export default AssetDetailsTitleBar;
