import React, { FunctionComponent } from 'react';
import { useConfigContext } from '../contexts/ConfigContext';
import { useAnalyticsContext } from '../contexts/AnalyticsContext';
import Filters from './Filters';
import { isMobile } from 'react-device-detect';

const AnalyticsControlContainer: FunctionComponent = () => {
    const configData = useConfigContext();
    const analyticsContext = useAnalyticsContext();

    const filtersProps = {
        filters: configData?.pages.analytics.filters,
        appliedFilters: analyticsContext.state.appliedFilters,
        setAppliedFilters: analyticsContext.setters.setAppliedFilters,

        filterCountRecord: analyticsContext.state.filterCountRecord,
    };

    return (
        <>
            <div className="filter-controls">
                <h2>{configData?.pages.analytics.page_title || ''}</h2>
                {!isMobile && <Filters {...filtersProps} />}
            </div>
            {isMobile && (
                <div className="filter-controls mobile">
                    <Filters {...filtersProps} />
                </div>
            )}
        </>
    );
};

export default AnalyticsControlContainer;
