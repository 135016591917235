import React from 'react';
import ModalBase from './ModalBase';

interface DeleteModalProps {
    isActive: boolean;
    onClose: () => void;
    onDelete: () => void;
}
let baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:8000';
if (baseURL !== 'http://localhost:8000') {
    baseURL += '/api';
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    isActive,
    onClose,
    onDelete,
}) => {
    return isActive ? (
        <ModalBase onClose={onClose}>
            <div className="delete-modal-content">
                <h3>Delete asset</h3>
                <div className="delete-text">
                    If you confirm, the asset will be permanently deleted. This
                    action cannot be undone.
                </div>
                <div className="button-row">
                    <div className="cancel-button" onClick={onClose}>
                        Cancel
                    </div>
                    <div className="delete-button" onClick={onDelete}>
                        Delete
                    </div>
                </div>
            </div>
        </ModalBase>
    ) : null;
};

export default DeleteModal;
